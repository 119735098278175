import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserService from "../Service/UserService";
import CourseService from "../Service/MasterCourseService";

/**
 * UpdateUser component to manage and update user information.
 * @returns {JSX.Element} The UpdateUser component.
 */
function UpdateCourse() {
  const navigate = useHistory();
  const { courseId } = useParams();

  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  const [courseData, setCourseData] = useState({
    groupCourseId: "",
    groupCourseName:"",
    componentCourseId: "",
    componentCourseName: "",
    durationInHours: "",
    durationInDays: "",
    modeOfLearning: "",
    track: "",
  });

  /**
   * Fetches user data when the component mounts or userId changes.
   * @param {string} userId - The ID of the user to fetch data for.
   */
  useEffect(() => {
    fetchCourseDataByCourseId(courseId);
  }, [courseId]);

  /**
   * Fetches user data by ID from the server.
   * @param {string} userId - The ID of the user to fetch data for.
   */
  const fetchCourseDataByCourseId = async (courseId) => {
    try {
      const response = await CourseService.getCourse(courseId);
      const { groupCourseId,groupCourseName, componentCourseId, componentCourseName,durationInHours,durationInDays, modeOfLearning, track } = response;
      setCourseData({ groupCourseId,groupCourseName, componentCourseId, componentCourseName,durationInHours,durationInDays, modeOfLearning, track });
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  /**
   * Handles input changes and updates the userData state.
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => { 
    const { name, value } = e.target;
    setCourseData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  /**
   * Handles the form submission to update user data.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to update this course?"
      );
      if (confirmDelete) {
        await CourseService.updateCourse(courseId, courseData);
        navigate.push({
          pathname: "/admin/course-management",
          state: { user }, 
        });
      }
    } catch (error) {
      console.error("Error updating course data:", error);
      alert(error);
    }
  };

  return (
    <div>
    <h2 className="text-2xl font-bold text-gray-800">Update Course</h2><br/>
    <form onSubmit={handleSubmit} className="max-w-lg p-6 bg-white rounded-lg shadow-md">
    <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Group Course ID:
    </label>
    <input
      type="text"
      name="groupCourseId"
      value={courseData.groupCourseId}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="groupCourseId"
    />
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Group Course Name:
    </label>
    <input
      type="text"
      name="groupCourseName"
      value={courseData.groupCourseName}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="groupCourseName"
    />
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Component Course ID:
    </label>
    <input
      type="text"
      name="componentCourseId"
      value={courseData.componentCourseId}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="componentCourseId"
    />
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Component Course Name:
    </label>
    <input
      type="text"
      name="componentCourseName"
      value={courseData.componentCourseName}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="componentCourseName"
    />
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Duration In Hours:
    </label>
    <input
      type="text"
      name="durationInHours"
      value={courseData.durationInHours}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="durationInHours"
    />
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Duration In Days:
    </label>
    <input
      type="text"
      name="durationInDays"
      value={courseData.durationInDays}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="durationInDays"
    />
  </div>

  <div className="mb-6">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Mode of Learning:
    </label>
    <select
      name="modeOfLearning"
      value={courseData.modeOfLearning}
      onChange={handleInputChange}
      required
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="modeOfLearning"
    >
      <option value="">--Select--</option>
      <option value="VILT">VILT</option>
      <option value="In-Person">In-Person</option>
      <option value="Blended">Blended</option>
      <option value="Self-Paced">Self-Paced</option>
	  </select>
  </div>

  <div className="mb-4">
    <label className="block text-gray-700 text-sm font-semibold mb-2">
      Track:
    </label>
    <input
      type="text"
      name="track"
      value={courseData.track}
      onChange={handleInputChange}
      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      id="track"
    />
  </div>

  <button
    type="submit"
    className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    Update Course
  </button>
</form>

    </div>
  );
}

export default UpdateCourse;
