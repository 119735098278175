import React from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { PageLayout } from "./Component/PageLayout";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "./authConfig";

import { Route, Switch, useHistory } from 'react-router-dom';

import WelcomeName from "./Component/WelcomeName";
import UserManagementPage from "./Component/UserManagement/UserManagementPage";
import AddUser from "./Component/UserManagement/AddUser";
import UpdateUser from "./Component/UserManagement/UpdateUser";
import Footer from "./Component/Common/Footer"; 
import LearningOfferings from "./Component/Learning/LearningOfferings";
import CourseManagementPage from "./Component/CourseManagement/CourseManagementPage";
import UpdateCourse from "./Component/CourseManagement/UpdateCourse";
import AddCourse from "./Component/CourseManagement/AddCourse";
import SMENomination from "./Component/Learning/SMENomination";
import CheckSMENominationStatus from "./Component/Learning/CheckSMENominationStatus";
import NominationFinalization from "./Component/Learning/NominationFinalization";
import { FreezeNominationProvider } from "./Component/Context/FreezeNominationContext";

import EffortUpdation from "./Component/EffortTracker/EffortUpdation";
import EffortRetrieval from "./Component/EffortTracker/EffortRetrieval";

import SkillReportPage from "./Component/SkillManagement/SkillReportPage";
import SkillManagementPage from "./Component/SkillManagement/SkillManagementPage";
import SMESkillManagementPage from "./Component/SkillManagement/SMESkillManagementPage";
import AddSkill from "./Component/SkillManagement/AddSkill";
import SMEAddSkillPage from "./Component/SkillManagement/SMEAddSkillPage";

function App({ pca }) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <div className="flex flex-col min-h-screen">
        <PageLayout>
          <div className="flex-grow container mx-auto">
            <Pages />
          </div>
        </PageLayout>
        <Footer />
      </div>
    </MsalProvider>
  );
}

function Pages() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  console.log("Logged in as : " + activeAccount.idTokenClaims.name);

  useEffect(() => {
    if (activeAccount) {
      fetchAccessToken();
    }
  }, [activeAccount]);

  const fetchAccessToken = async () => {
    if (!activeAccount) {
      console.error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      return;
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      const bearer = `Bearer ${response.accessToken}`;
      localStorage.setItem("token", bearer);
    } catch (error) {
      console.error("Error fetching access token:", error);
      localStorage.clear();
    }
  };

  return (
    <>
      <div className="App">
        <WelcomeName /> 
      </div>

      <Switch>
        <Route path="/admin/user-management" component={UserManagementPage} />
        <Route path="/admin/add-user" component={AddUser} />
        <Route path="/admin/update-user/:userId" component={UpdateUser} />
        <Route path="/admin/effortReterival" component={EffortRetrieval} />

        <Route path="/admin/course-management" component={CourseManagementPage} />
        <Route path="/admin/add-course" component={AddCourse} />
        <Route path="/admin/update-course/:courseId" component={UpdateCourse} />
        <Route path="/admin/effortUpdation" component={EffortUpdation} />

        <Route path="/admin/skill-report" component={SkillReportPage} />
        <Route path="/admin/skill-management" component={SkillManagementPage} />
        <Route path="/admin/add-skill" component={AddSkill} />
        <Route path="/sme/skill-management" component={SMESkillManagementPage} />
        <Route path="/sme/skill-map" component={SMEAddSkillPage} />
        
        <FreezeNominationProvider>
        <Route path="/admin/Nomination" component={SMENomination} />
        <Route path="/admin/Nominationstatus" component={CheckSMENominationStatus} />
        <Route path="/admin/Nominationfinalize" component={NominationFinalization} />
        <Route path="/admin/learningOfferings" component={LearningOfferings}/>
        </FreezeNominationProvider>

        
        
      </Switch>
    </>
  );
}

export default App;
