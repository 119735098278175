import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import SkillService from "../Service/SkillService";
import UserService from "../Service/UserService";
import "./styles.css";
import { useHistory } from "react-router-dom";

const SMEAddSkillPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const user = location.state?.user;
  const navigate = useHistory();

  const [tracks, setTracks] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState("");

  useEffect(() => {
    fetchTracks();
  }, []);

  useEffect(() => {
    if (selectedTrack) {
      fetchSkillsData(selectedTrack);
    }
  }, [selectedTrack]);

  const fetchTracks = async () => {
    try {
      setLoading(true);
      const response = await SkillService.getAllTracks();
      setTracks(response || []); // Ensure response is an array
    } catch (err) {
      console.error("Error fetching tracks:", err);
      setError("Failed to fetch tracks.");
    } finally {
      setLoading(false);
    }
  };

  const fetchSkillsData = async (track) => {
    try {
      setLoading(true);
      const skills = await SkillService.getUnMappedSkillsByTrack(track, user.userId);
      const transformedData = skills.map((skill) => ({
        smeSkillMappingId: skill.smeSkillMappingId,
        skillId: skill.skillId,
        skillName: skill.skillName,
        rating: skill.rating || "0", // Populate with data from backend
        myCompetencyProficiency: skill.myCompetencyProficiency || "", // Populate with data from backend
        certification: skill.certification || "",
      }));
      setData(transformedData);
    } catch (err) {
      setError("No Skill Available");
    } finally {
      setLoading(false);
    }
  };


  const updateMyData = (rowIndex, columnId, value) => {
     setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnId]: value };
        }
        return row;
      })
    );
  };

  const filteredData = useMemo(() => {
      return data.filter((row) =>
        row.skillName?.toLowerCase().includes(search.toLowerCase())
      );
    }, [data, search]);

  const columns = useMemo(
      () => [
        {
          Header: "Skill ID",
          accessor: "skillId",
        },
        {
          Header: "Skill",
          accessor: "skillName",
        },
        {
          Header: "Rating",
          accessor: "rating",
          Cell: ({ value, row, column }) => (
            <select
              value={value}
              onChange={(e) =>
                updateMyData(row.index, column.id, e.target.value)
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="0">0 - No Experience</option>
              <option value="1">1 - Basic</option>
              <option value="2">2 - Intermediate</option>
              <option value="3">3 - Advanced</option>
              <option value="4">4 - Expert</option>
            </select>
          ),
        },
        {
          Header: "Proficiency",
          accessor: "myCompetencyProficiency",
          Cell: ({ value, row, column }) => (
            <select
              value={value}
              onChange={(e) =>
                updateMyData(row.index, column.id, e.target.value)
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Proficiency</option>
              <option value="P1 Beginner">P1 Beginner</option>
              <option value="P2 Intermediate">P2 Intermediate</option>
              <option value="P3 Advanced">P3 Advanced</option>
              <option value="P4 Expert">P4 Expert</option>
              <option value="P5 Master">P5 Master</option>
            </select>
          ),
        },
        {
          Header: "Certification",
          accessor: "certification",
          Cell: ({ value, row, column }) => (
            <input
              type="text"
              value={value}
              onChange={(e) =>
                updateMyData(row.index, column.id, e.target.value)
              }
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          ),
        },
        {
          Header: "Actions",
          Cell: ({ row }) => (
            <button onClick={() => handleAddToSkills(row.original)}>
              Add Skill
            </button>
          ),
          disableSortBy: true,
          disableFilters: true,
        },
      ],
      []
    );
  
    const handleAddToSkills = async (skill) => {
      const payload = {
          smeSkillMappingId: skill.smeSkillMappingId,
          skillId: skill.skillId,
          userId: user.userId,
          rating: skill.rating || "0",
          myCompetencyProficiency: skill.myCompetencyProficiency || "",
          certification: skill.certification || "",
      };
  
      try {
          const response = await SkillService.mapSkillToSME(payload);
          if (response) {
              alert(`Skill '${response.skillName}' has been successfully added.`);
          }
          navigate.push({
            pathname: "/sme/skill-management",
            state: { user }, 
          });
      } catch (err) {
          alert(err.message || "Failed to add skill. Please try again.");
      }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false, // Prevent resetting to the first page
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) return <p>Loading...</p>;
 
  return (
    <div>
      <h2>SME Add Skill Page</h2>
      <table>
        <tr>
          <td>
          <label htmlFor="track">Choose a Track:</label>
            <select
              id="track"
              value={selectedTrack}
              onChange={(e) => setSelectedTrack(e.target.value)}
              className="px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a track</option>
              {tracks.map((track, index) => (
                <option key={index} value={track}>
                  {track}
                </option>
              ))}
            </select>
          </td>
          <td>
          <input
            type="text"
            placeholder="Search by Skill"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          </td>
        </tr>
      </table>
      
      {data.length === 0 ? (
        <p>No skills available.</p>
      ) : (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      )}
      <div>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>
        <span>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SMEAddSkillPage;