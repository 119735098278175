import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import SkillService from "../Service/SkillService";
import "./styles.css";


const SMESkillManagementPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const user = location.state?.user;

  useEffect(() => {
    if (user) {
      fetchSkillsData();
    }
  }, [user]);

  const fetchSkillsData = async () => {
    try {
      setLoading(true);
      const skills = await SkillService.getAllSkillsByUserId(user.userId);
      const transformedData = skills.map((skill) => ({
        smeSkillMappingId: skill.smeSkillMappingId,
        skillId: skill.skillId,
        skillName: skill.skillName,
        rating: skill.rating || "0", // Populate with data from backend
        myCompetencyProficiency: skill.myCompetencyProficiency || "", // Populate with data from backend
        certification: skill.certification || "",
      }));
      setData(transformedData);
    } catch (err) {
      setError("Failed to fetch skill details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnId]: value };
        }
        return row;
      })
    );
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      row.skillName?.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  const columns = useMemo(
    () => [
      {
        Header: "Skill ID",
        accessor: "skillId",
      },
      {
        Header: "Skill",
        accessor: "skillName",
      },
      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ value, row, column }) => (
          <select
            value={value}
            onChange={(e) =>
              updateMyData(row.index, column.id, e.target.value)
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="0">0 - No Experience</option>
            <option value="1">1 - Basic</option>
            <option value="2">2 - Intermediate</option>
            <option value="3">3 - Advanced</option>
            <option value="4">4 - Expert</option>
          </select>
        ),
      },
      {
        Header: "Proficiency",
        accessor: "myCompetencyProficiency",
        Cell: ({ value, row, column }) => (
          <select
            value={value}
            onChange={(e) =>
              updateMyData(row.index, column.id, e.target.value)
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="P1 Beginner">P1 Beginner</option>
            <option value="P2 Intermediate">P2 Intermediate</option>
            <option value="P3 Advanced">P3 Advanced</option>
            <option value="P4 Expert">P4 Expert</option>
            <option value="P5 Master">P5 Master</option>
          </select>
        ),
      },
      {
        Header: "Certification",
        accessor: "certification",
        Cell: ({ value, row, column }) => (
          <input
            type="text"
            value={value}
            onChange={(e) =>
              updateMyData(row.index, column.id, e.target.value)
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button onClick={() => handleAddToSkills(row.original)}>
            Update Skill
          </button>
        ),
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    []
  );

  const handleAddToSkills = async (skill) => {
    const payload = {
        smeSkillMappingId: skill.smeSkillMappingId,
        skillId: skill.skillId,
        userId: user.userId,
        rating: skill.rating || "0",
        myCompetencyProficiency: skill.myCompetencyProficiency || "",
        certification: skill.certification || "",
    };

    try {
        const response = await SkillService.mapSkillToSME(payload);
        if (response) {
            alert(`Skill '${response.skillName}' has been successfully updated.`);

            // Fetch updated skills and wait for state update
            const updatedSkills = await SkillService.getAllSkillsByUserId(user.userId);

            // Transform updated skills data (same logic as fetchSkillsData)
            const transformedData = updatedSkills.map((skill) => ({
                smeSkillMappingId: skill.smeSkillMappingId,
                skillId: skill.skillId,
                skillName: skill.skillName,
                rating: skill.rating || "0",
                myCompetencyProficiency: skill.myCompetencyProficiency || "",
                certification: skill.certification || "",
            }));

            setData(transformedData); // Update state with new skills

            // Find index of the newly added skill in the updated data
            const addedSkillIndex = transformedData.findIndex(
                (item) => item.smeSkillMappingId === response.smeSkillMappingId
            );

            if (addedSkillIndex !== -1) {
                // Calculate the page where the newly added skill appears
                const newPageIndex = Math.floor(addedSkillIndex / pageSize);
                gotoPage(newPageIndex); // Navigate to that page
            }
        } else {
            alert("Failed to add skill. Please try again.");
        }
    } catch (err) {
        alert(err.message || "Failed to add skill. Please try again.");
    }
};


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      updateMyData,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false, // Prevent resetting to the first page
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) return <p>Loading skills data...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Skills Management</h2>
      <table>
        <tr>
          <td>
            <input
              type="text"
              placeholder="Search by Skill"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                padding: "8px",
                marginBottom: "10px",
                width: "300px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </td>
          <td>
          <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
            <Link to={{
                        pathname: "/sme/skill-map",
                        state: { user } 
                      }}>Add Skill Details</Link>
          </button>
          </td>
        </tr>
      </table>
      <table {...getTableProps()} style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.id !== "Actions" ? column.getSortByToggleProps() : {})} 
                  style={{
                    borderBottom: "2px solid black",
                    textAlign: "left",
                    padding: "8px",
                    cursor: column.id !== "Actions" ? "pointer" : "default",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {/* Show sorting icon only for sortable columns */}
                    {column.id !== "Actions" && !column.isSorted && (
                      <span style={{ marginLeft: "5px", color: "#bbb" }}>⬍</span>
                    )}
                    {column.isSorted && (column.isSortedDesc ? " 🔽" : " 🔼")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ padding: "8px" }}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>{" "}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => gotoPage(Number(e.target.value) - 1)}
            style={{ width: "50px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
      <br></br><p><b>NOTE: </b><font color='#ff0000'> Please reach out to admin for adding new skill </font></p> 
    </div>
  );
};

export default SMESkillManagementPage;
