// FreezeNominationContext.js
import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";

// Create a context
const FreezeNominationContext = createContext();

// Create a provider component
export const FreezeNominationProvider = ({ children}) => {
  const [isFrozen, setIsFrozen] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL1;
   const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  // Fetch the freeze state on mount
  useEffect(() => {
    const fetchFreezeState = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/api/freeze/getFreezeState/${user.track}`);
        setIsFrozen(response.data.frozen);
      } catch (error) {
        console.error("Error fetching freeze state:", error);
      }
    };
    fetchFreezeState();
  }, [user]);

  const toggleFreezeNomination = async () => {
    try {
      const newFrozenState = !isFrozen;
      const response = await axios.post(`${BASE_URL}/admin/api/freeze/updateFreezeState`, { "track": user.track,"frozen": newFrozenState });
      if(response!=null){
        setIsFrozen(newFrozenState);
    }
    } catch (error) {
      console.error("Error updating freeze state:", error);
    }
  };

  return (
    <FreezeNominationContext.Provider value={{ isFrozen, toggleFreezeNomination }}>
      {children}
    </FreezeNominationContext.Provider>
  );
};

// Custom hook to use the context
export const useFreezeNomination = () => useContext(FreezeNominationContext);
