import axios from "axios";

class SkillService {
  static BASE_URL = process.env.REACT_APP_BASE_URL2;


  static async getAllTracks() {
    try {
      const response = await axios.get(`${SkillService.BASE_URL}/admin/api/skill-master/get-all-tracks`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllSkillsByUserId(userId) {
    try {
      const response = await axios.get(`${SkillService.BASE_URL}/sme/api/skill-mapping/get-skill-mapping-details/`+userId);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
 
  //Skill Management Page
  static async getAllSkillsByTrack(track) {
    try {
      const response = await axios.get(`${SkillService.BASE_URL}/admin/api/skill-master/get-all-skills-by-track/${track}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllSMEsSkillsByTrack(track) {
    try {
      const response = await axios.get(`${SkillService.BASE_URL}/admin/api/skill-mapping/get-all-skill-mapping-details/${track}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async mapSkillToSME(payload) {
    try {
      const response = await axios.post(`${SkillService.BASE_URL}/sme/api/skill-mapping/map-skill-sme`, payload);
      return response.data; // Directly return the response data
    } catch (error) {
    console.error("API Error:", error.response || error.message);
    throw new Error(
      error.response?.data?.message || "Failed to communicate with the server."
    );
    }
  }

  //Admin functionalities - Skill Master CRUP operations
  static async getUnMappedSkillsByTrack(track, userId) {
    try {
      const response = await axios.get(`${SkillService.BASE_URL}/admin/api/skill-master/get-unmapped-skills-by-track/${track}/${userId}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async addSkill(skillData) {
    try {
      const response = await axios.post(`${SkillService.BASE_URL}/admin/api/skill-master/add-skill`,skillData);
      return response.data;
    } catch (err) {
      console.error("Error fetching user by ID:", err);
      throw err;
    }
  }

 /* static async updateSkill(skillId, payload) {
    try {
      const response = await axios.put(`${SkillService.BASE_URL}/admin/api/skill-master/update-skill/`+skillId, payload);
      return response.data; // Directly return the response data
    } catch (error) {
    console.error("API Error:", error.response || error.message);
    throw new Error(
      error.response?.data?.message || "Failed to communicate with the server."
    );
    }
  } */

  static async deleteSkill(skillId) {
    try {
      const response = await axios.delete(`${SkillService.BASE_URL}/admin/api/skill-master/delete-skill/`+skillId);
      return response.data; // Directly return the response data
    } catch (error) {
    console.error("API Error:", error.response || error.message);
    throw new Error(
      error.response?.data?.message || "Failed to communicate with the server."
    );
    }
  }


  
}

export default SkillService;