import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SkillService from "../Service/SkillService";
import { useLocation } from "react-router-dom";

/**
 * RegistrationPage component for user registration.
 * @returns {JSX.Element} The RegistrationPage component.
 */
function AddSkill() {
  const navigate = useHistory();

  const [formData, setFormData] = useState({
    skillName: "",
    track: "",
  });

  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  /**
   * Handles input changes and updates the form data state.
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /**
   * Handles the form submission for user registration.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await SkillService.addSkill(formData);
      setFormData({
        skillName: "",
        track: "",
      });
      alert("Skill added successfully");
      navigate.push({
        pathname: "/admin/skill-management",
        state: { user }, 
      });

    } catch (error) {
      console.error("Error adding skill:", error);
      alert("An error occurred while adding skill");
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800">Add User</h2><br/>
      <form onSubmit={handleSubmit} className="max-w-lg p-6 bg-white rounded-lg shadow-md">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">
            Skill Name:
          </label>
          <input
            type="text"
            name="skillName"
            value={formData.skillName}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
            Track:
          </label>
          <input
            type="track"
            name="track"
            value={formData.track}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="email"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add Skill
        </button>
      </form>
    </div>
  );
}

export default AddSkill;
