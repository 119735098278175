import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import SkillService from "../Service/SkillService";
import "./styles.css";

const SkillReportPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const user = location.state?.user;

  useEffect(() => {
    if (user) {
      fetchSkillsData();
    }
  }, [user]);

  const fetchSkillsData = async () => {
    try {
      setLoading(true);
      const skills = await SkillService.getAllSMEsSkillsByTrack(user.track);
      const transformedData = skills.map((skill) => ({
        smeSkillMappingId: skill.smeSkillMappingId,
        skillId: skill.skillId,
        skillName: skill.skillName,
        userId: skill.userId,
        name: skill.userName,
        rating: skill.rating || "0", // Populate with data from backend
        myCompetencyProficiency: skill.myCompetencyProficiency || "", // Populate with data from backend
        certification: skill.certification || "",
        designation: skill.designation,
      }));
      setData(transformedData);
    } catch (err) {
      setError("Failed to fetch skill details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setData((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnId]: value };
        }
        return row;
      })
    );
  };

  const ratingLabels = {
    0: "0 - No Experience",
    1: "1 - Basic",
    2: "2 - Intermediate",
    3: "3 - Advanced",
    4: "4 - Expert",
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      row.skillName?.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  const columns = useMemo(
    () => [
      
      {
        Header: "SME Name",
        accessor: "name",
      },
      
      {
        Header: "Designation",
        accessor: "designation",
      },

      {
        Header: "Skill",
        accessor: "skillName",
      },

      {
        Header: "Rating",
        accessor: "rating",
        Cell: ({ value }) => ratingLabels[value] || `${value} - Unknown`, 
      },
      {
        Header: "Proficiency",
        accessor: "myCompetencyProficiency",
      },
      {
        Header: "Certification",
        accessor: "certification",
      },
      
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      updateMyData,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false, // Prevent resetting to the first page
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) return <p>Loading skills data...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Skills Management</h2>
      <input
        type="text"
        placeholder="Search by Skill"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          padding: "8px",
          marginBottom: "10px",
          width: "300px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />

      <table {...getTableProps()} style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())} 
                  style={{
                    borderBottom: "2px solid black",
                    textAlign: "left",
                    padding: "8px",
                    cursor: "default",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {/* Show sorting icon */}
                    {!column.isSorted && (
                      <span style={{ marginLeft: "5px", color: "#bbb" }}>⬍</span>
                    )}
                    {column.isSorted && (column.isSortedDesc ? " 🔽" : " 🔼")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ padding: "8px" }}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>{" "}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => gotoPage(Number(e.target.value) - 1)}
            style={{ width: "50px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SkillReportPage;
