import React from 'react'
import { NavLink } from 'react-router-dom';

export default function SMEHome({ name,user }) {
  return (
    <div>
          <div className="absolute top-0 right-8 m-5">
          <b>{name}</b>
        </div>
        <div className="absolute top-3 right-0 m-3">
        <img className="object-scale-down h-8 w-8" src="/useravatar.png" alt="User name icon"/>
        </div>
        <div className="absolute text-sm top-5 right-7 m-5">
        <i>&nbsp;SME</i>
        </div>
        
        <h2 className="text-3xl font-semibold text-center text-black-600 my-10">Skill and Learning Offering Management Tool</h2>
       
      <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto">
        <ul className="flex justify-center space-x-4 p-4">
          <li>
              <NavLink
              to={{
                pathname: "/admin/Nomination",
                state: { user } 
				      }}
				      exact
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Nomination
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/admin/Nominationstatus", state: { user } }}
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Check Nomination status
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/admin/effortUpdation", state: { user } }}
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Effort Updation
              </NavLink>
            </li>
            
          
        </ul>
      </div>
    </nav>
    </div>
  )
}
