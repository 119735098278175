import React, { useEffect, useState , useMemo } from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import { useLocation } from "react-router-dom";
import SkillService from "../Service/SkillService";
import "./styles.css";

/**
 * SkillManagementPage component to manage and display skill information.
 * @returns {JSX.Element} The SkillManagementPage component.
 */
function SkillManagementPage() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  useEffect(() => {
    fetchSkills();
  },[]);

  /**
   * Fetches all skills from the server.
   */
  const fetchSkills = async () => {
    try {
      setLoading(true);
      const response = await SkillService.getAllSkillsByTrack(user.track);
      const transformedData = response.map((skill) => ({
        skillId: skill.skillId,
        skillName: skill.skillName,
        track: skill.track,
      }));
      setSkills(transformedData);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setError("Failed to fetch skill details. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const updateMyData = (rowIndex, columnId, value) => {
    setSkills((oldData) =>
      oldData.map((row, index) => {
        if (index === rowIndex) {
          return { ...row, [columnId]: value };
        }
        return row;
      })
    );
  };

  const filteredData = useMemo(() => {
    return skills.filter((row) =>
      row.skillName?.toLowerCase().includes(search.toLowerCase())
    );
  }, [skills, search]);

  const columns = useMemo(
    () => [
      
      {
        Header: "Skill Id",
        accessor: "skillId",
      },
      
      {
        Header: "Skill Name",
        accessor: "skillName",
      },

      {
        Header: "Track",
        accessor: "track",
      },    
      {
        Header: "Actions",
        Cell: ({ row }) => (
            <button onClick={() => handleDeleteSkill(row.original)}>
                Delete Skill
            </button>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    ],
    []
  );

  const handleDeleteSkill = async (skill) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this skill?");
      if (!confirmDelete) return;
  
      const response = await SkillService.deleteSkill(skill.skillId);
      
      console.log("Response received:", response); 
  
      if (response === true) {  
        console.log("Skill deletion successful:", response);
        setSkills((prevSkills) => prevSkills.filter((s) => s.skillId !== skill.skillId));
        alert("Skill deleted successfully.");
      } 
    } catch (err) {
      console.error("Error deleting skill:", err);
      alert(err.response?.data?.message || "This skill is mapped to an SME, so it cannot be deleted.");
    }
  };
  


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      updateMyData,
      initialState: { pageIndex: 0, pageSize: 5 },
      autoResetPage: false, // Prevent resetting to the first page
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) return <p>Loading skills data...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div><br/>
      <h2 className="text-2xl w-1/3 mx-auto font-semibold mb-4">Skills Management Page</h2><br/>
      <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
        <Link to={{
                        pathname: "/admin/add-skill",
                        state: { user } 
                      }}>Add Skill</Link>
      </button>
      <br/><br/>
      <input
        type="text"
        placeholder="Search by Skill"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <table {...getTableProps()} style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                {...column.getHeaderProps(column.id !== "Actions" ? column.getSortByToggleProps() : {})} 
                style={{
                  borderBottom: "2px solid black",
                  textAlign: "left",
                  padding: "8px",
                  cursor: column.id !== "Actions" ? "pointer" : "default",
                }}
              >
                {column.render("Header")}
                <span>
                  {/* Show sorting icon only for sortable columns */}
                  {column.id !== "Actions" && !column.isSorted && (
                    <span style={{ marginLeft: "5px", color: "#bbb" }}>⬍</span>
                  )}
                  {column.isSorted && (column.isSortedDesc ? " 🔽" : " 🔼")}
                </span>
              </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ padding: "8px" }}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ marginTop: "10px" }}>
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>{" "}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => gotoPage(Number(e.target.value) - 1)}
            style={{ width: "50px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
      
export default SkillManagementPage;
