import React, { useEffect, useState } from "react";
import MonthlyOfferingService from "../Service/MonthlyOfferingService";
import { useLocation } from "react-router-dom";
import NominationService from "../Service/NominationService";
import { useFreezeNomination } from "../Context/FreezeNominationContext";
import { format } from "date-fns";

function SMENomination() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state
  const { isFrozen } = useFreezeNomination(); // Get isFrozen state from context

  const [error, setError] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [monthlyCoursesOffered, setMonthlyCoursesOffered] = useState([]);
  const [allMonthlyCoursesOffered, setAllMonthlyCoursesOffered] = useState([]);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    if (selectedMonth !== "") {
      fetchCoursesByMonth();
      fetchCoursesByMonth2();
    }
  }, [selectedMonth]);

  const fetchCoursesByMonth = async () => {
    try {
      const response = await MonthlyOfferingService.getAllUnnominatedCoursesBySMEWithinSelectedMonthAndTrack(user.userId, user.track, selectedMonth);
      console.log("Response is "+ JSON.stringify(response, 0, 2));
      setMonthlyCoursesOffered(response);
      setShowTable(response.length !== 0);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowTable(false);
    }
  };

  const fetchCoursesByMonth2 = async () => {
    try {
      const response = await MonthlyOfferingService.getAllCoursesWithinSelectedMonthAndTrack(selectedMonth, user.track);
      setAllMonthlyCoursesOffered(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (course) => {
      setSelectedCourses((prevSelected) =>
        prevSelected.some(item => item.monthlyOfferingId === course.monthlyOfferingId)
          ? prevSelected.filter(item => item.monthlyOfferingId !== course.monthlyOfferingId)
          : [...prevSelected, { monthlyOfferingId: course.monthlyOfferingId, userId: user.userId, confirmationStatus: "NOMINATED" }]
      );
  };

  const isCourseSelected = (courseId) => selectedCourses.some(item => item.monthlyOfferingId === courseId);

  const handleSaveNomination = async () => {
    if (selectedCourses.length === 0) {
      alert("No courses selected for nomination");
      return;
    }
    const now = new Date();
    const istOffset = 5 * 60 + 30; // 5 hours 30 minutes in minutes
    const istTime = new Date(now.getTime() + istOffset * 60 * 1000);
    const nominationTime = istTime.toISOString();
    const nominationsWithTime = selectedCourses.map(course => ({ ...course, nominationTime }));

    try {
      const response = await NominationService.saveNomination(nominationsWithTime);
      if (response === true) {
        alert("Your nomination was saved successfully");
        const updatedCourses = monthlyCoursesOffered.filter(course => !selectedCourses.some(sel => sel.monthlyOfferingId === course.monthlyOfferingId));
        setMonthlyCoursesOffered(updatedCourses);
        if (updatedCourses.length === 0) {
          setShowTable(false);
        }
        setSelectedCourses([]);
      }
    } catch (error) {
      setError("Failed to save nominations");
      console.error("Error saving courses:", error);
    }
  };
  
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="overflow-x-auto p-6 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-semibold mb-4" align="center">Nomination Page</h2>
      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="month">Select Month:</label>
        <input
          id="month"
          type="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
          required
        />
      </div>

      {showTable && (
        <div className="mt-6 w-full overflow-x-auto">
          <p className={`${
              isFrozen
                ? "bg-red-200 text-red-800 p-4 rounded-lg border border-red-300"
                : ""
            }`}
          >
            {isFrozen
              ? "Nomination are frozen, changes are not allowed currently."
              : ""}
          </p>
          <br/>
          <h3 className="text-xl font-semibold mb-4">Nominate for a course</h3>
          
          <CoursesTable courses={monthlyCoursesOffered} 
          handleCheckboxChange={handleCheckboxChange} 
          isCourseSelected={isCourseSelected} showNominateColumn={true} isFrozen={isFrozen}/>
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleSaveNomination}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              disabled={isFrozen}
            >
              Save Nomination
            </button>
          </div>
        </div>
      )}

      {selectedMonth && monthlyCoursesOffered.length === 0 && allMonthlyCoursesOffered.length!== 0 && (
        <p className="text-center text-gray-700 mt-4">No Learning offerings available/opened for nomination.</p>
      )}

      {selectedMonth && allMonthlyCoursesOffered.length=== 0 && (
        <p className="text-center text-gray-700 mt-4">No Learning offerings planned for the selected month</p>
      )}

      {selectedMonth && allMonthlyCoursesOffered.length!== 0 &&(
        <div className="mt-4 flex justify-center">
          <button
            onClick={toggleModal}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Show All Offerings
          </button>
        </div>
      )}

      <Modal showModal={showModal} toggleModal={toggleModal} allCourses={allMonthlyCoursesOffered} />
    </div>
  );
}

const CoursesTable = ({ courses, handleCheckboxChange, isCourseSelected, showNominateColumn,isFrozen }) => (
  <table className="min-w-full bg-white border border-gray-200">
    <thead>
      <tr>
        
        <th className="px-4 py-2 border border-gray-300">Group Course Name</th>
        <th className="px-4 py-2 border border-gray-300">Component Course Name</th>
        <th className="px-4 py-2 border border-gray-300">Mode of Learning</th>
        <th className="px-4 py-2 border border-gray-300">Duration (Hours)</th>
        <th className="px-4 py-2 border border-gray-300">Duration (Days)</th>
        <th className="px-4 py-2 border border-gray-300">Start Date</th>
        <th className="px-4 py-2 border border-gray-300">End Date</th>
        <th className="px-4 py-2 border border-gray-300">Time</th>
        <th className="px-4 py-2 border border-gray-300">Program Name</th>
        <th className="px-4 py-2 border border-gray-300">Comment</th>
        {showNominateColumn && (
          <th className="px-4 py-2 border border-gray-300">Nominate</th>
        )}
      </tr>
    </thead>
    <tbody>
      {courses.map((course) => (
        <tr key={course.monthlyOfferingId} className="border-b border-gray-200">
          
          <td className="px-4 py-2 border border-gray-300">{course.groupCourseName}</td>
          <td className="px-4 py-2 border border-gray-300">{course.componentCourseName}</td>
          <td className="px-4 py-2 border border-gray-300">{course.modeOfLearning}</td>
          <td className="px-4 py-2 border border-gray-300">{course.durationInHours}</td>
          <td className="px-4 py-2 border border-gray-300">{course.durationInDays}</td>
          <td className="px-4 py-2 border border-gray-300 whitespace-nowrap">
            {format(new Date(course.startDate), "dd-MMM-yyyy")}
          </td>
          <td className="px-4 py-2 border border-gray-300 whitespace-nowrap">
            {format(new Date(course.endDate), "dd-MMM-yyyy")}
          </td>
          <td className="px-4 py-2 border border-gray-300">{course.time}</td>
          <td className="px-4 py-2 border border-gray-300">{course.programName}</td>
          <td className="px-4 py-2 border border-gray-300">{course.comment}</td>
          {showNominateColumn && (
            <td className="px-4 py-2 border border-gray-300">
              <input
                type="checkbox"
                checked={isCourseSelected(course.monthlyOfferingId)}
                onChange={() => handleCheckboxChange(course)}
                disabled={isFrozen}
              />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);


const Modal = ({ showModal, toggleModal, allCourses }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 max-w-6xl w-full overflow-hidden">
        <h3 className="text-xl font-semibold mb-4">All Monthly Courses Offered</h3>
        <div className="overflow-y-auto max-h-96">
          <CoursesTable courses={allCourses} handleCheckboxChange={() => {}} isCourseSelected={() => false} showNominateColumn={false} />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={toggleModal}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SMENomination;