import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import EffortService from "../Service/EffortService";

function EffortUpdation() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  const [error, setError] = useState(null);
  const [years, setYears] = useState([]); // State to hold years data
  const [selectedYearId, setSelectedYearId] = useState(""); // State for selected year ID
  const [months, setMonths] = useState([]); // State to hold months data
  const [selectedMonthId, setSelectedMonthId] = useState(""); // State for selected month ID
  const [entryLevelTrainingDays, setEntryLevelTrainingDays] = useState(""); // State to hold training days
  const [lateralTrainingDays, setLateralTrainingDays] = useState(""); // State to hold training days
  const [effortData, setEffortData] = useState([]); // State to hold effort data
  const [editingEffortId, setEditingEffortId] = useState(null); // Track the row being edited

  // Fetch years on component mount
  useEffect(() => {
    const fetchYears = async () => {
      try {
        const data = await EffortService.getYears();
        setYears(data);
      } catch (err) {
        setError("Failed to fetch years");
        console.error(err);
      }
    };

    fetchYears();
  }, []);

  // Fetch months when a year is selected
  useEffect(() => {
    if (!selectedYearId) {
      setMonths([]); // Clear months if no year is selected
      return;
    }

    const fetchMonths = async () => {
      try {
        const data = await EffortService.getMonths(selectedYearId);
        setMonths(data);
      } catch (err) {
        setError("Failed to fetch months");
        console.error(err);
      }
    };

    fetchMonths();
  }, [selectedYearId]);

  // Fetch effort data when year changes
  useEffect(() => {
    const fetchEffortData = async () => {
      if (!selectedYearId) {
        setEffortData([]);
        return;
      }

      try {
        const data = await EffortService.getEffortData1(user?.userId, selectedYearId);
        setEffortData(data);
      } catch (err) {
        setError("Failed to fetch effort data");
        console.error(err);
      }
    };

    fetchEffortData();
  }, [selectedYearId, user?.userId]);

  // Handle numeric input validation for Training Days
  const handleEntryLevelTrainingDaysChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEntryLevelTrainingDays(value); // Allow only numeric input
    }
  };

  const handleLateralTrainingDaysChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setLateralTrainingDays(value); // Allow only numeric input
    }
  };
  const handleAddOrUpdateEffort = async () => {
    // Construct the JSON object
    const effortData = {
      id: editingEffortId, // Include ID only if updating
      yearId: parseInt(selectedYearId, 10),
      monthId: parseInt(selectedMonthId, 10),
      userId: user?.userId, // Ensure userId is taken from user object
      entryLevelTrainingDays: parseInt(entryLevelTrainingDays, 10),
      lateralTrainingDays: parseInt(lateralTrainingDays, 10),
    };

    try {
      if (editingEffortId) {
        // Update existing effort
        await EffortService.addEfforts(effortData);
        alert("Effort updated successfully");
      } else {
        // Add new effort
        await EffortService.addEfforts(effortData);
        alert("Effort added successfully");
      }

      // Reset form and reload effort data
      setEditingEffortId(null);
      setEntryLevelTrainingDays("");
      setLateralTrainingDays("");
      setSelectedMonthId("");
      const data = await EffortService.getEffortData1(user?.userId, selectedYearId);
      setEffortData(data);
    } catch (error) {
      console.error("Failed to save effort:", error);
      alert("Failed to save effort. Please try again.");
    }
  };

  const handleEdit = (effort) => {
    setEditingEffortId(effort.id);
    setSelectedYearId(effort.yearId.toString());
    setSelectedMonthId(effort.monthId.toString());
    setEntryLevelTrainingDays(effort.entryLevelTrainingDays.toString());
    setLateralTrainingDays(effort.lateralTrainingDays.toString());
  };

  return (
    <div className="overflow-x-auto p-6 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-semibold mb-4" align="center">
        Effort Updation Page
      </h2>

      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="year">
          Select Year:
        </label>
        <select
          id="year"
          value={selectedYearId}
          onChange={(e) => setSelectedYearId(e.target.value)}
          className="block w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="" disabled>
            -- Select a Year --
          </option>
          {years.map((year) => (
            <option key={year.id} value={year.id}>
              {year.year}
            </option>
          ))}
        </select>
      </div>

      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="month">
          Select Month:
        </label>
        <select
          id="month"
          value={selectedMonthId}
          onChange={(e) => setSelectedMonthId(e.target.value)}
          className="block w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          disabled={!months.length}
        >
          <option value="" disabled>
            {months.length ? "-- Select a Month --" : "Select a year first"}
          </option>
          {(effortData && effortData.length > 0
                ? months.filter(
                    (month) =>
                      !effortData.some((effort) => effort.monthId === month.id) ||
                      month.id.toString() === selectedMonthId
                  )
                : months
              ).map((month) => (
                <option key={month.id} value={month.id}>
                  {month.month}
                </option>
            ))}
        </select>

      </div>


      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="training-days">
          Entry Level Days:
        </label>
        <input
          id="entry-level-training-days"
          type="text"
          value={entryLevelTrainingDays}
          onChange={handleEntryLevelTrainingDaysChange}
          className="block w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Enter number of training days"
        />
      </div>

      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="training-days">
          Lateral Days:
        </label>
        <input
          id="lateral-training-days"
          type="text"
          value={lateralTrainingDays}
          onChange={handleLateralTrainingDaysChange}
          className="block w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Enter number of training days"
        />
      </div>

      <div className="w-1/3 mx-auto mt-4">
        <button
          onClick={handleAddOrUpdateEffort}
          className="w-full px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          disabled={!selectedYearId || !selectedMonthId || !entryLevelTrainingDays ||!lateralTrainingDays}
        >
          {editingEffortId ? "Update Effort" : "Add Effort"}
        </button>
      </div>

      {/* Effort Data Table */}
      {effortData.length > 0 ? (
        <div className="overflow-x-auto mt-6">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border border-gray-300">S.No</th>
                <th className="px-4 py-2 border border-gray-300">Year</th>
                <th className="px-4 py-2 border border-gray-300">Month</th>
                <th className="px-4 py-2 border border-gray-300">Entry Level Days</th>
                <th className="px-4 py-2 border border-gray-300">Lateral Days</th>
                <th className="px-4 py-2 border border-gray-300">Total Training Days</th>
                <th className="px-4 py-2 border border-gray-300">Actions</th>
              </tr>
            </thead>
            <tbody>
              {effortData.map((effort,index) => (
                <tr key={effort.id}>
                  <td className="px-4 py-2 border border-gray-300 text-center">{index + 1}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.yearName}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.monthName}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.entryLevelTrainingDays}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.lateralTrainingDays}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.entryLevelTrainingDays+effort.lateralTrainingDays}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">
                    <button
                      onClick={() => handleEdit(effort)}
                      className="px-4 py-1 text-white bg-green-500 rounded hover:bg-green-600"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))}

          <tr className="bg-gray-200 font-bold">
              <td className="px-4 py-2 border border-gray-300 text-center" colSpan="3">Total</td>
              <td className="px-4 py-2 border border-gray-300 text-center">
                {effortData.reduce((sum, effort) => sum + (effort.entryLevelTrainingDays || 0), 0)}
              </td>
              <td className="px-4 py-2 border border-gray-300 text-center">
                {effortData.reduce((sum, effort) => sum + (effort.lateralTrainingDays || 0), 0)}
              </td>
              <td className="px-4 py-2 border border-gray-300 text-center">
                {effortData.reduce((sum, effort) => sum + (effort.entryLevelTrainingDays || 0) + (effort.lateralTrainingDays || 0), 0)}
              </td>
              <td className="px-4 py-2 border border-gray-300 text-center"></td> {/* Empty cell for Actions */}
            </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4">
          No effort data available. Select a year to load data.
        </p>
      )}
    </div>
  );
}

export default EffortUpdation;
