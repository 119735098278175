import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserService from "../Service/UserService";

/**
 * UserManagementPage component to manage and display user information.
 * @returns {JSX.Element} The UserManagementPage component.
 */
function UserManagementPage() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  },[]);

  /**
   * Fetches all users from the server.
   */
  const fetchUsers = async () => {
    try {
      const response = await UserService.getAllUsersByTrack(user.track);
      //const response = await UserService.getAllUsers();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  /**
   * Deletes a user by ID.
   * @param {string} userId - The ID of the user to delete.
   */
  const deleteUser = async (userId) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this user?");
      if (confirmDelete) {
        await UserService.deleteUser(userId);
        setUsers(users.filter((user) => user.userId !== userId));
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <div><br/>
      <h2 className="text-2xl w-1/3 mx-auto font-semibold mb-4">Users Management Page</h2><br/>
      <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
        <Link to={{
                pathname: "/admin/add-user",
                state: { user } 
              }}>Add User</Link>
      </button>
      <br/><br/>
      <table className="w-11/12 mx-auto bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              ID
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              Name
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              Email
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              Designation
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              Track
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
              Role
            </th>
            <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300" colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.userId}>
              <td className="py-2 px-4 border border-gray-300">{user.userId}</td>
              <td className="py-2 px-4 border border-gray-300">{user.name}</td>
              <td className="py-2 px-4 border border-gray-300">{user.email}</td>
              <td className="py-2 px-4 border border-gray-300">{user.designation}</td>
              <td className="py-2 px-4 border border-gray-300">{user.track}</td>
              <td className="py-2 px-4 border border-gray-300">{user.role}</td>
              <td className="py-2 px-4 border border-gray-300">
                <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400" onClick={() => deleteUser(user.userId)}>
                  Delete
                </button>
                </td>
                <td className="py-2 px-4 border border-gray-300">
                <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                  <Link
                  to={{
                    pathname: `/admin/update-user/${user.userId}`,
                    state: { user } 
                  }}
                    className="text-purple-700 hover:underline"
                  >
                    Update
                  </Link>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br/>
    </div>
  );
}

export default UserManagementPage;
