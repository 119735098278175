import React from 'react'
import { NavLink } from 'react-router-dom';

export default function AdminHome({ name,user }) {
  return (
    <div>
      <div className="absolute top-0 right-8 m-5">
      <b>{name}</b>
      </div>
      <div className="absolute top-3 right-0 m-3">
      <img className="object-scale-down h-8 w-8" src="/useravatar.png" alt="User name icon"/>
      </div>
      <div className="absolute text-sm top-5 right-7 m-5">
      <i>&nbsp;Admin</i>
      </div>
      
      <h2 className="text-3xl font-semibold text-center text-black-600 my-10">Skill and Learning Offering Management Tool</h2>
       
      <nav className="bg-gray-800">
        <div className="max-w-9xl mx-auto">
          <ul className="flex justify-center space-x-1 p-2">
            {/* User Management */}
            <li>
              <NavLink
              to={{
                pathname: "/admin/user-management",
                state: { user },
				}}
                exact
                className="text-white hover:bg-gray-700 px-4 py-2 text-sm"
                activeClassName="bg-gray-700"
              >
                User Management
              </NavLink>
            </li>
            
            {/* Learning Management Dropdown */}
            <li className="relative group">
              <span className="text-white px-4 py-4 text-sm cursor-pointer">Learning Management</span>
              <ul className="absolute left-0 hidden group-hover:block bg-gray-800 mt-1">
                <li>
                  <NavLink
				  to={{
					pathname: "/admin/course-management",
					state: { user },
					}}
                    exact
                    className="text-white text-sm hover:bg-gray-700 px-4 py-4 block"
                    activeClassName="bg-gray-700"
                  >
                    Course Management
                  </NavLink>
                </li>
                <li>
                  <NavLink
				  to={{
					pathname: "/admin/learningOfferings",
					state: { user },
					}}
					exact
                    className="text-white text-sm hover:bg-gray-700 px-4 py-4 block"
                    activeClassName="bg-gray-700"
                  >
                    Add Learning Offerings
                  </NavLink>
                </li>
                <li>
                  <NavLink
				  to={{
					pathname: "/admin/Nominationfinalize",
					state: { user },
					}}
					exact
                    className="text-white text-sm hover:bg-gray-700 px-4 py-4 block"
                    activeClassName="bg-gray-700"
                  >
                    Finalize Nomination
                  </NavLink>
                </li>
              </ul>
            </li>
            
            {/* Effort Management Dropdown */}
            <li>
              <NavLink
              to={{
                pathname: "/admin/effortReterival",
                state: { user },
				}}
                exact
                className="text-white hover:bg-gray-700 px-4 py-2 text-sm"
                activeClassName="bg-gray-700"
              >
                Effort Details
              </NavLink>
            </li>
            
            
            {/* Separation after "Finalize Nomination" */}
            <li className="border-l border-white-500 h-full mx-2">|</li>
            
            <li>
              <NavLink
              to={{
                pathname: "/admin/Nomination",
                state: { user } 
				}}
				exact
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Nomination
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/admin/Nominationstatus", state: { user } }}
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Check Nomination status
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/admin/effortUpdation", state: { user } }}
                className="text-white hover:bg-gray-700 px-1 py-1 text-sm"
                activeClassName="bg-gray-700"
              >
                SME - Effort Updation
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}
