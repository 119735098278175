import React, { useState, useEffect, useMemo } from "react";
import EffortService from "../Service/EffortService";
import { useLocation } from "react-router-dom";

function EffortRetrieval() {
  const [error, setError] = useState(null);
  const [years, setYears] = useState([]); // State to hold years data
  const [selectedYearId, setSelectedYearId] = useState(""); // Selected year ID
  const [effortData, setEffortData] = useState([]); // Effort data
  const [selectedDesignation, setSelectedDesignation] = useState(""); // Selected Designation

  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  // Fetch years on component mount
  useEffect(() => {
    const fetchYears = async () => {
      try {
        const data = await EffortService.getYears();
        setYears(data);
      } catch (err) {
        setError("Failed to fetch years");
        console.error(err);
      }
    };
    fetchYears();
  }, []);

  // Fetch effort data when year changes
  useEffect(() => {
    const fetchEffortData = async () => {
      if (!selectedYearId) {
        setEffortData([]);
        return;
      }
      try {
        const data = await EffortService.getEffortData2(selectedYearId, user.track);
        setEffortData(data);
      } catch (err) {
        setError("Failed to fetch effort data");
        console.error(err);
      }
    };
    fetchEffortData();
  }, [selectedYearId]);

  // Extract unique Designation & Month values
  const uniqueDesignations = useMemo(() => {
    return [...new Set(effortData.map((effort) => effort.userDesignation))];
  }, [effortData]);

  // Filter data based on selected values
  const filteredData = useMemo(() => {
    return effortData.filter((effort) => {
      return (
        (selectedDesignation ? effort.userDesignation === selectedDesignation : true)
      );
    });
  }, [effortData, selectedDesignation]);

  return (
    <div className="overflow-x-auto p-6 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-semibold mb-4 text-center">Effort Details Page</h2>

      {error && <p className="text-red-500 text-center">{error}</p>}

      {/* Year Selection */}
      <div className="w-1/3 mx-auto mb-4">
        <label className="block text-gray-700" htmlFor="year">Select Year:</label>
        <select
          id="year"
          value={selectedYearId}
          onChange={(e) => setSelectedYearId(e.target.value)}
          className="block w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="" disabled>-- Select a Year --</option>
          {years.map((year) => (
            <option key={year.id} value={year.id}>{year.year}</option>
          ))}
        </select>
      </div>

      {/* Filters: Designation & Month */}
      {effortData.length > 0 && (
        <div className="w-1/3 mx-auto mb-4">
          <label className="block text-gray-700" htmlFor="year">Choose Career Level(CL):</label>
          {/* Designation Filter */}
          <select
            className="border border-gray-300 p-2 rounded"
            value={selectedDesignation}
            onChange={(e) => setSelectedDesignation(e.target.value)}
          >
            <option value="">All CL</option>
            {uniqueDesignations.map((designation) => (
              <option key={designation} value={designation}>{designation}</option>
            ))}
          </select>
        </div>
      )}

      {/* Effort Data Table */}
      {filteredData.length > 0 ? (
        <div className="overflow-x-auto mt-6">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border border-gray-300">S.No</th>
                <th className="px-4 py-2 border border-gray-300">SME Name</th>
                <th className="px-4 py-2 border border-gray-300">CL</th>
                <th className="px-4 py-2 border border-gray-300">Year</th>
                <th className="px-4 py-2 border border-gray-300">Total Months</th>
                <th className="px-4 py-2 border border-gray-300">Total Entry Level Days</th>  
                <th className="px-4 py-2 border border-gray-300">Total Lateral Days</th> 
                <th className="px-4 py-2 border border-gray-300">Total Training Days</th>  
              </tr>
            </thead>
            <tbody>
              {filteredData.map((effort, index) => (
                <tr key={effort.id}>
                  <td className="px-4 py-2 border border-gray-300 text-center">{index + 1}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.userName}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.userDesignation}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.yearName}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.monthId}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.entryLevelTrainingDays}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.lateralTrainingDays}</td>
                  <td className="px-4 py-2 border border-gray-300 text-center">{effort.entryLevelTrainingDays+effort.lateralTrainingDays}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-500 mt-4">
          No effort data available. Select a year to load data.
        </p>
      )}
    </div>
  );
}

export default EffortRetrieval;
