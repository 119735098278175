import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CourseService from "../Service/MasterCourseService";

function AddCourse() {
  const navigate = useHistory();

  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  const [formData, setFormData] = useState({
    groupCourseId: "NA",
    groupCourseName: "NA",
    componentCourseId: "",
    componentCourseName: "",
    durationInHours: "",
    durationInDays: "",
    modeOfLearning: "", // New field for modeOfLearning dropdown
    courseType: "componentCourse", // New field for radio button selection
    track:"",
  });

  const [groupCourseOptions, setGroupCourseOptions] = useState([]);

  useEffect(() => {
    if (formData.courseType === "existingGroupCourse") {
      fetchGroupCourseOptions();
    }
  }, [formData.courseType]);

  const fetchGroupCourseOptions = async () => {
    try {
      const response = await CourseService.getGroupCourseNamesByTrack(user.track); // Replace with your API endpoint
      setGroupCourseOptions(response);
    } catch (error) {
      console.error("Error fetching group course options:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCourseTypeChange = (e) => {
    const courseType = e.target.value;
    setFormData({
      ...formData,
      courseType: courseType,
      groupCourseId: courseType === "componentCourse" ? "NA" : "",
      groupCourseName: courseType === "componentCourse" ? "NA" : "",
    });
  };

  const handleGroupCourseIdChange = (e) => {
    setFormData({ ...formData, groupCourseId: e.target.value });
  };

  const handleGroupCourseNameChange = (e) => {
    setFormData({ ...formData, groupCourseName: e.target.value });
  };

  const handleDropdownChange = async (e) => {
    const selectedGroupCourseName = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      groupCourseName: selectedGroupCourseName,
    }));

    if (selectedGroupCourseName) {
      try {
        const response = await CourseService.getGroupCourseID(selectedGroupCourseName);
        setFormData((prevState) => ({
          ...prevState,
          groupCourseId: response,
        }));
      } catch (error) {
        console.error("Error fetching group course name:", error);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        groupCourseId: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await CourseService.addCourse(formData);
      setFormData({
        groupCourseId: "",
        groupCourseName: "",
        componentCourseId: "",
        componentCourseName: "",
        durationInHours: "",
        durationInDays: "",
        modeOfLearning: "", // Reset modeOfLearning
        courseType: "",
        track: "",
      });
      alert("Course added successfully");
      navigate.push({
        pathname: "/admin/course-management",
        state: { user }, 
      });
    } catch (error) {
      console.error("Error adding course:", error);
      alert("An error occurred while adding the course");
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Add Course</h2>
      <form onSubmit={handleSubmit} className="max-w-2xl p-8 bg-white rounded-lg shadow-md">
        <div className="mb-6">
          {/* Radio buttons for course type */}
          <span className="block text-gray-700 text-sm font-semibold mb-2">Course Type:</span>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="courseType"
              value="componentCourse"
              checked={formData.courseType === "componentCourse"}
              onChange={handleCourseTypeChange}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Component Course</span>
          </label>

          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              name="courseType"
              value="newGroupCourse"
              checked={formData.courseType === "newGroupCourse"}
              onChange={handleCourseTypeChange}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">New Group Course</span>
          </label>

          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              name="courseType"
              value="existingGroupCourse"
              checked={formData.courseType === "existingGroupCourse"}
              onChange={handleCourseTypeChange}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Existing Group Course</span>
          </label>
        </div>

        {/* Conditionally render input field for groupCourseId */}
        {formData.courseType === "newGroupCourse" && (
          <div className="mb-6">
            <label htmlFor="groupCourseId" className="block text-gray-700 text-sm font-semibold mb-2">
              Group Course ID:
            </label>
            <input
              type="text"
              name="groupCourseId"
              value={formData.groupCourseId}
              onChange={handleGroupCourseIdChange}
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="groupCourseId"
            />
            <label htmlFor="groupCourseId" className="block text-gray-700 text-sm font-semibold mb-2">
              Group Course Name:
            </label>
            <input
              type="text"
              name="groupCourseName"
              value={formData.groupCourseName}
              onChange={handleGroupCourseNameChange}
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="groupCourseName"
            />
          </div>
        )}

        {/* Conditionally render dropdown for existing group courses */}
        {formData.courseType === "existingGroupCourse" && (
          <div className="mb-6">
            <label htmlFor="existingGroupCourseId" className="block text-gray-700 text-sm font-semibold mb-2">
              Existing Group Course Name:
            </label>
            <select
              name="groupCourseName"
              value={formData.groupCourseName}
              onChange={handleDropdownChange}
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="groupCourseName"
            >
              <option value="">Select a Group Course</option>
              {groupCourseOptions.map((groupCourseOption) => (
                <option key={groupCourseOption} value={groupCourseOption}>
                  {groupCourseOption}
                </option>
              ))}
            </select>
            <label htmlFor="existingGroupCourseId" className="block text-gray-700 text-sm font-semibold mb-2">
              Existing Group Course ID:
            </label>
            <input
              type="text"
              name="groupCourseId"
              value={formData.groupCourseId}
              readOnly
              required
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="groupCourseId"
            />
          </div>
        )}

        <div className="mb-6">
          <label htmlFor="componentCourseId" className="block text-gray-700 text-sm font-semibold mb-2">
            Component Course ID:
          </label>
          <input
            type="text"
            name="componentCourseId"
            value={formData.componentCourseId}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="componentCourseId"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="componentCourseName" className="block text-gray-700 text-sm font-semibold mb-2">
            Component Course Name:
          </label>
          <input
            type="text"
            name="componentCourseName"
            value={formData.componentCourseName}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="componentCourseName"
          />
        </div>

        {/* Dropdown for Mode of Learning */}
        <div className="mb-6">
          <label htmlFor="modeOfLearning" className="block text-gray-700 text-sm font-semibold mb-2">
            Mode of Learning:
          </label>
          <select
            name="modeOfLearning"
            value={formData.modeOfLearning}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="modeOfLearning"
          >
            <option value="">--Select--</option>
            <option value="VILT">VILT</option>
            <option value="In-Person">In-Person</option>
            <option value="Blended">Blended</option>
            <option value="Self-Paced">Self-Paced</option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="durationInHours" className="block text-gray-700 text-sm font-semibold mb-2">
            Duration in Hours:
          </label>
          <input
            type="number"
            name="durationInHours"
            value={formData.durationInHours}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="durationInHours"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="durationInDays" className="block text-gray-700 text-sm font-semibold mb-2">
            Duration in Days:
          </label>
          <input
            type="number"
            name="durationInDays"
            value={formData.durationInDays}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="durationInDays"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="track" className="block text-gray-700 text-sm font-semibold mb-2">
            Track:
          </label>
          <input
            type="text"
            name="track"
            value={formData.track}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="track"
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 px-6 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Add Course
        </button>
      </form>
    </div>
  );
}

export default AddCourse;
