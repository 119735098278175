import axios from "axios";

/**
 * CourseService class to handle course-related API calls.
 */
class EffortService {
  static BASE_URL = process.env.REACT_APP_BASE_URL3;

  /**
   * Fetches all group course ids from the backend.
   * @returns {Promise<Array>} The list of all group course IDS.
   * @throws Will throw an error if the request fails.
   */
  static async getYears() {
    try {
      const response = await axios.get(
        `${EffortService.BASE_URL}/getYears`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getMonths(yearId) {
    try {
      const response = await axios.get(
        `${EffortService.BASE_URL}/getMonths/${yearId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getEffortData1(userId,yearId) {
    try {
      const response = await axios.get(
        `${EffortService.BASE_URL}/retrieveUserEfforts/${userId}/${yearId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getEffortData2(yearId, track) {
    try {
      const response = await axios.get(
        `${EffortService.BASE_URL}/retrieveSumOfEfforts/${yearId}/${track}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getEffortData3(yearId, track) {
    try {
      const response = await axios.get(
        `${EffortService.BASE_URL}/retrieveEfforts/${yearId}/${track}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async addEfforts(effort) {
    try {
      const response = await axios.post(`${EffortService.BASE_URL}/addEffort`,effort);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}
export default EffortService;
