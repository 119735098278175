import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CourseService from "../Service/MasterCourseService";

/**
 * UserManagementPage component to manage and display user information.
 * @returns {JSX.Element} The UserManagementPage component.
 */
function CourseManagementPage() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
  },[]);

  /**
   * Fetches all users from the server.
   */
  const fetchCourses = async () => {
    try {
      //const response = await CourseService.getAllCourses();
      const response = await CourseService.getAllCoursesByTrack(user.track);
      setCourses(response);
    } catch (error) {
      console.error("Error fetching Courses:", error);
    }
  };

  /**
   * Deletes a user by ID.
   * @param {string} userId - The ID of the user to delete.
   */
  const deleteCourse = async (courseId) => {
   try {
      const confirmDelete = window.confirm("Are you sure you want to delete this course?");
      if (confirmDelete) {
        await CourseService.deleteCourse(courseId);
        setCourses(courses.filter((course) => course.courseId !== courseId));
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  return (
  <div><br/>
  <h2 className="text-2xl w-1/3 mx-auto font-semibold mb-4">Courses Management Page</h2><br/>
  <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
    <Link to={{
                pathname: "/admin/add-course",
                state: { user } 
              }}>Add Course</Link>
  </button><br/><br/>
  <table className="w-11/12 mx-auto bg-white border border-gray-300">
    <thead>
      <tr>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Group Course Code
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Group Course Name
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Component Course Code
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Component Course Name
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Mode Of Learning
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Duration In Hours
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Duration In Days
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300">
          Track
        </th>
        <th className="py-2 px-4 bg-gray-200 text-left text-gray-600 font-bold border border-gray-300" colSpan="2">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      {courses.map((course) => (
        <tr key={course.courseId}>
          <td className="py-2 px-4 border border-gray-300">{course.groupCourseId}</td>
          <td className="py-2 px-4 border border-gray-300">{course.groupCourseName}</td>
          <td className="py-2 px-4 border border-gray-300">{course.componentCourseId}</td>
          <td className="py-2 px-4 border border-gray-300">{course.componentCourseName}</td>
          <td className="py-2 px-4 border border-gray-300">{course.modeOfLearning}</td>
          <td className="py-2 px-4 border border-gray-300">{course.durationInHours}</td>
          <td className="py-2 px-4 border border-gray-300">{course.durationInDays}</td>
          <td className="py-2 px-4 border border-gray-300">{course.track}</td>
          <td className="py-2 px-4 border border-gray-300">
            <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400" onClick={() => deleteCourse(course.courseId)}>
              Delete
            </button>
          </td>
          <td className="py-2 px-4 border border-gray-300">
            <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
              <Link
                to={{
                  pathname: `/admin/update-course/${course.courseId}`,
                  state: { user } 
                }}
                className="text-purple-700 hover:underline"
              >
                Update
              </Link>
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  <br/>
  
</div>

  );
}

export default CourseManagementPage;
