import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../Service/UserService";
import { useLocation } from "react-router-dom";

/**
 * UpdateUser component to manage and update user information.
 * @returns {JSX.Element} The UpdateUser component.
 */
function UpdateUser() {
  const navigate = useHistory();
  const { userId } = useParams();

  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    role: "",
    designation: "",
    track: "",
  });

  /**
   * Fetches user data when the component mounts or userId changes.
   * @param {string} userId - The ID of the user to fetch data for.
   */
  useEffect(() => {
    fetchUserDataById(userId);
  }, [userId]);

  /**
   * Fetches user data by ID from the server.
   * @param {string} userId - The ID of the user to fetch data for.
   */
  const fetchUserDataById = async (userId) => {
    try {
      const response = await UserService.getUser(userId);
      const { name, email, role, designation, track } = response;
      setUserData({ name, email, role, designation, track });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  /**
   * Handles input changes and updates the userData state.
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  /**
   * Handles the form submission to update user data.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to update this user?"
      );
      if (confirmDelete) {
        await UserService.updateUser(userId, userData);
        navigate.push(
          {
            pathname: "/admin/user-management",
            state: { user }, 
          }
          );
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
      alert(error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800">Update User</h2><br/>
      <form onSubmit={handleSubmit} className="max-w-lg p-6 bg-white rounded-lg shadow-md">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">
            Name:
          </label>
          <input
            type="text"
            name="name"
            value={userData.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="email"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="role" className="block text-gray-700 text-sm font-semibold mb-2">
            Role:
          </label>
          <select
            name="role"
            value={userData.role}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="role"
          >
            <option value="ADMIN">ADMIN</option>
            <option value="SME">SME</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="designation" className="block text-gray-700 text-sm font-semibold mb-2">
            Designation:
          </label>
          <input
            type="text"
            name="designation"
            value={userData.designation}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="designation"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="track" className="block text-gray-700 text-sm font-semibold mb-2">
            Track:
          </label>
          <input
            type="text"
            name="track"
            value={userData.track}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="track"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Update User
        </button>
      </form>
    </div>
  );
}

export default UpdateUser;
