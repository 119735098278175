import axios from "axios";

/**
 * UserService class to handle user-related API calls and authentication.
 */
class UserService {
  static BASE_URL = process.env.REACT_APP_BASE_URL1;

  static async getUserDetails(username) {
    try {
      const response = await axios.get(`${UserService.BASE_URL}/admin/api/users/get-user/${username}`);
      return response.data;
    } catch (err) {
      console.error("Error fetching user details:", err);
      throw err;
    }
  }

  static async getUser(userId) {
    try {
      const response = await axios.get(`${UserService.BASE_URL}/admin/api/users/get-user-userId/${userId}`);
      return response.data;
    } catch (err) {
      console.error("Error fetching user details by userId:", err);
      throw err;
    }
  }

  static async register(userData) {
    try {
      const response = await axios.post(`${UserService.BASE_URL}/admin/api/users/register-user`,userData);
      return response.data;
    } catch (err) {
      console.error("Error fetching user by ID:", err);
      throw err;
    }
  }

  // static async getAllUsers() {
  //   try {
  //     const response = await axios.get(`${UserService.BASE_URL}/admin/api/users/get-all-users`);
  //     return response.data;
  //   } catch (err) {
  //     console.error("Error fetching user by ID:", err);
  //     throw err;
  //   }
  // }

  static async getAllUsersByTrack(track) {
    try {
      const response = await axios.get(`${UserService.BASE_URL}/admin/api/users/get-all-users/${track}`);
      return response.data;
    } catch (err) {
      console.error("Error fetching user by ID:", err);
      throw err;
    }
  }

  static async updateUser(userId, userData) {
    try {
      const response = await axios.put(`${UserService.BASE_URL}/admin/api/users/update-user/${userId}`,userData);
      return response.data;
    } catch (err) {
      console.error("Error updating user:", err);
      throw err;
    }
  }

  static async deleteUser(userId) {
    try {
      const response = await axios.delete(`${UserService.BASE_URL}/admin/api/users/delete-user/${userId}`);
      return response.data;
    } catch (err) {
      console.error("Error deleting user:", err); 
      throw err;
    }
  }
}

export default UserService;
